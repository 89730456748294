import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import EnterName from "../pages/EnterName/EnterName";
import EnterPan from "../pages/EnterPan/EnterPan";
import PanVerify from "../pages/PanVerify/PanVerify";
import EnterAadhaar from "../pages/EnterAadhaar/EnterAadhaar";
import DigilockerVerify from "../pages/DigilockerVerify/DigilockerVerify";
import LoanPurpose from "../pages/LoanPurpose/LoanPurpose";
import LoanAmount from "../pages/LoanAmount/LoanAmount";
import Gender from "../pages/Gender/Gender";
import Marital from "../pages/Marital/Marital";
import HighestEducation from "../pages/HighestEducation/HighestEducation";
import EmployementDetails from "../pages/EmployementDetails/EmployementDetails";
import PaymentMode from "../pages/PaymentMode/PaymentMode";
import CompanyName from "../pages/CompanyName/CompanyName";
import Industry from "../pages/Industry/Industry";
import Designation from "../pages/Designation/Designation";
import MonthlyIncome from "../pages/MonthlyIncome/MonthlyIncome";
import WorkExperience from "../pages/WorkExperience/WorkExperience";
import SalaryDate from "../pages/SalaryDate/SalaryDate";
import YourResidence from "../pages/YourResidence/YourResidence";
import EnterAddress from "../pages/EnterAddress/EnterAddress";
import Finbox from "../pages/Finbox/Finbox";
import LoanApproval from "../pages/LoanApproval/LoanApproval";
import ReferenceDetails from "../pages/ReferenceDetails/ReferenceDetails";
import Selfie from "../pages/Selfie/Selfie";
import EnterBankDetails from "../pages/EnterBankDetails/EnterBankDetails";
import Emandate from "../pages/Emandate/Emandate";
import EmandateStatus from "../pages/Emandate/EmandateStatus";
import BankList from "../pages/BankList/BankList";
import Login from "../pages/Login/Login";
import NotFound from "../pages/NotFound/NotFound";
import TermsCondition from "../pages/TermsCondition/TermsCondition";
import FinboxReject from "../pages/Finbox/FinboxReject";
import Dashboard from "../pages/Dashboard/Dashboard";
import Logout from "../pages/Logout/Logout";
import ConfirmDetails from "../pages/ConfirmDetails/ConfirmDetails";
import Contact from "../pages/Contact/Contact";
import FinboxCreate from "../pages/Finbox/FinboxCreate";
import { Home } from "../pages/Home";
import { EnterMobile } from "../pages/EnterMobile";
import { EnterOTP } from "../pages/EnterOTP";
import PanNotLinked from "../pages/PanNotLinked/PanNotLinked";

import { SelectLoan } from "../pages/SelectLoan";

import useGoogleTag from "../hooks/useGoogleTag";
import Repayment from "../pages/Repayment";

export default function AppRoute() {
  useGoogleTag();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<EnterMobile />} />
      <Route path="/enter-otp" element={<EnterOTP />} />
      <Route path="/home" element={<Home />} />

      <Route path="/enter-name" element={<EnterName />} />
      <Route path="/enter-your-pan" element={<EnterPan />} />
      <Route path="/pan-verify" element={<PanVerify />} />
      <Route path="/enter-aadhar" element={<EnterAadhaar />} />
      <Route path="/pan-not-linked" element={<PanNotLinked />} />

      <Route path="/digilocker-verify" element={<DigilockerVerify />} />
      <Route path="/loan-purpose" element={<LoanPurpose />} />
      <Route path="/enter-loan-amount" element={<LoanAmount />} />
      <Route path="/gender" element={<Gender />} />
      <Route path="/marital-status" element={<Marital />} />
      <Route path="/highest-education" element={<HighestEducation />} />
      <Route path="/employment-details" element={<EmployementDetails />} />
      <Route path="/payment-mode" element={<PaymentMode />} />
      <Route path="/company-name" element={<CompanyName />} />
      <Route path="/industry" element={<Industry />} />
      <Route path="/your-designation" element={<Designation />} />
      <Route path="/monthly-income" element={<MonthlyIncome />} />
      <Route path="/work-experience" element={<WorkExperience />} />
      <Route path="/salary-date" element={<SalaryDate />} />
      <Route path="/your-residence" element={<YourResidence />} />
      <Route path="/enter-address" element={<EnterAddress />} />
      <Route path="/finbox-create" element={<FinboxCreate />} />
      <Route path="/finbox" element={<Finbox />} />
      <Route path="/loan-reject" element={<FinboxReject />} />
      <Route path="/loan-approval" element={<LoanApproval />} />
      <Route path="/reference-details" element={<ReferenceDetails />} />
      <Route path="/selfie-verification" element={<Selfie />} />
      <Route path="/bank-details" element={<EnterBankDetails />} />

      <Route path="/emandate" element={<Emandate />} />
      <Route path="/emandate-status" element={<EmandateStatus />} />

      <Route path="/bank-list" element={<BankList />} />
      <Route path="/term-condition" element={<TermsCondition />} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/confirm-details" element={<ConfirmDetails />} />
      <Route path="/contact-us" element={<Contact />} />

      <Route path="/select-loan" element={<SelectLoan />} />
      <Route path="/re-payment" element={<Repayment />} />
    </Routes>
  );
}
