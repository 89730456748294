import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import styles from "./EnterOTP.module.scss";
import Loader from "../../components/Loader/Loader";
import { CustomButton, Header } from "../../components";
import DataSafetyMessage from "../../components/Messages/DataSafetyMessage";
import { enterotpValidation } from "../../validations/auth.validation";
import ServicesAPI from "../../services/services";
import { useLocation, useNavigate } from "react-router-dom";
import { storeToken } from "../../utils/storage";
import { useDispatch } from "react-redux";
import { addUserInfo, addUserName } from "../../redux/slices/userSlice";
import { WebEngageTrackingEvent, WebEngageUserAttributes } from "../../lib/webengage";

export default function EnterOTP() {
  const [seconds, setSeconds] = useState(60);
  const inputRef = useRef(null);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { mobileNumber } = location.state || {};

  const { enterMobileNumber, verifyOTPAPI } = new ServicesAPI();

  useEffect(() => {
    const second = setTimeout(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => clearTimeout(second);
  }, [seconds]);

  useEffect(() => {
    const timer = setTimeout(() => {
      inputRef.current.focus();
    }, 300);
    return () => clearTimeout(timer);
  }, []);

  const handleResendOTP = async () => {
    const res = await enterMobileNumber(mobileNumber);

    if (res && res.statusCode === 200) {
      setSeconds(60);
    }
  };

  return (
    <div className={`wrapper ${styles.home}`}>
      <Formik
        initialValues={{ otp: "" }}
        validationSchema={enterotpValidation}
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          setSubmitting(true);

          setTimeout(async () => {
            const res = await verifyOTPAPI(mobileNumber, values.otp);

            if (res && res.statusCode === 200) {
              const token = res.data.jwtToken;
              storeToken(token);
              dispatch(addUserName(res?.data?.customer?.name));
              dispatch(addUserInfo(res?.data?.customer));

              WebEngageUserAttributes("userId", res?.data?.customer?.customerID);
              WebEngageUserAttributes("phone", `91${res?.data?.customer?.mobile}`);
              WebEngageTrackingEvent("OTP Submitted");

              setTimeout(() => {
                navigate("/home");
              }, 100);
            } else {
              setFieldError("otp", res.message);
            }

            setSubmitting(false);
          }, 500);
        }}
      >
        {({ errors, values, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit} style={{ height: "100%" }}>
            <div className="vertical-column-layout">
              <div className="vertical-column-top">
                <Header />

                <div className="mb-2">
                  <p className={styles.label}>Enter OTP</p>
                  <p className={styles.sub__label}>Please enter the OTP</p>
                </div>

                <div className={styles.content}>
                  <input
                    type="tel"
                    className={styles.form__input}
                    placeholder=""
                    name="otp"
                    value={values.otp}
                    onChange={(e) => {
                      const input = e.target.value;
                      if (input != "000000000000" && /^\d*$/.test(input)) {
                        setFieldValue("otp", e.target.value);
                      }
                    }}
                    onBlur={(e) => {
                      setFieldValue("otp", e.target.value.trim());
                    }}
                    autoComplete="off"
                    ref={inputRef}
                  />

                  {touched.otp && errors.otp ? <div className="error-msg">{errors.otp}</div> : null}

                  <p className={styles.sub__label} style={{ marginTop: 10 }}>
                    Have not received your OTP?
                  </p>
                  <p className={styles.sub__label}>
                    <span
                      className={styles.resend}
                      onClick={() => {
                        if (seconds === 0) {
                          handleResendOTP();
                        }
                      }}
                    >
                      Resend
                    </span>{" "}
                    {seconds > 0 && <span>in {seconds} seconds</span>}
                  </p>
                </div>
              </div>

              <div className="vertical-column-bottom">
                <DataSafetyMessage />
                <CustomButton text="Verify" type="submit" />
              </div>
            </div>

            {isSubmitting && <Loader />}
          </form>
        )}
      </Formik>
    </div>
  );
}
