// import { getEnvironment } from "../utils/environment";

// const { apiUrl } = getEnvironment();

// Rozarpay Mandate
export const rozarPayMandate = async (data) => {
  const script = document.createElement("script");
  script.src = "https://checkout.razorpay.com/v1/checkout.js";
  script.async = true;
  document.body.appendChild(script);

  const host = window.location.href.split("/");

  script.onload = () => {
    const options = {
      key: process.env.REACT_APP_ROZAR_PAY_KEY,
      customer_id: data.customer_id,
      order_id: data.order_id,
      recurring: 1,
      callback_url: host[0] + "//" + host[2] + "/emandate-status",
      notes: {
        disableWebhook: true,
      },
      theme: {
        color: "#182bda",
      },
    };

    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };
};
