import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import AfterOnboardAPI from "../../services/AfterOnboardAPI";

import styles from "./Finbox.module.scss";

import Loader from "../../components/Loader/Loader";
import FinboxAPI from "../../services/finbox.api";

export default function Finbox() {
  const [finboxError, setFinboxError] = useState(false);
  const [processingCount, setProcessingCount] = useState(0);
  const [entityID, setEntityID] = useState("");

  const [apiStatus, setApiStatus] = useState("");

  const { upgradeLoanAmount } = useSelector((state) => state.user);

  const navigate = useNavigate();
  const location = useLocation();

  const { finboxBankConnectAPI, bankingSurrogateAPI } = new FinboxAPI();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const url = queryParams.get("url");
    const success = queryParams.get("success");
    const entity_id = queryParams.get("entity_id");

    if (url) {
      window.location.href = url;
    } else if (success === "true" && entity_id) {
      handlefinboxBankConnectAPI(entity_id);
      // setEntityID(entity_id);
    } else if (success === "false") {
      setFinboxError(true);
    }
  }, [location.search]);

  console.log("count----------", processingCount);

  useEffect(() => {
    handleCallAgain();
  }, [processingCount]);

  const handleCallAgain = () => {
    setTimeout(() => {
      const queryParams = new URLSearchParams(location.search);
      const entity_id = queryParams.get("entity_id");

      if (apiStatus === "processing") {
        handlefinboxBankConnectAPI(entity_id);
      }
    }, 2000);
  };

  const handlefinboxBankConnectAPI = async (entity_id) => {
    const res = await finboxBankConnectAPI(entity_id);

    if (res) {
      if (Object.keys(res.data).length > 0) {
        if (res.data.api_status === "processing") {
          console.log("status-----------", res.data.api_status);
          setApiStatus(res.data.api_status);

          if (processingCount < 4) {
            setProcessingCount((prev) => prev + 1);
            console.log("count---------", processingCount);
          } else {
            switch (res.data.pageName) {
              case "approval":
                window.location.href = "/loan-approval";
                break;
              case "dashboard":
                window.location.href = "/dashboard";
                break;
              case "rejected":
                window.location.href = "/loan-reject";
                break;
              default:
                window.location.href = "/dashboard";
                break;
            }
          }
        } else {
          // check loan amount upgrade
          if (upgradeLoanAmount) {
            handleBankingSurrogateAPI();
          } else {
            switch (res.data.pageName) {
              case "approval":
                window.location.href = "/loan-approval";
                break;
              case "dashboard":
                window.location.href = "/dashboard";
                break;
              case "rejected":
                window.location.href = "/loan-reject";
                break;
              default:
                window.location.href = "/dashboard";
                break;
            }
          }
        }
      }
    } else {
      setFinboxError(true);
    }
  };

  const handleBankingSurrogateAPI = async () => {
    const res = await bankingSurrogateAPI();
    if (res) {
      if (res?.data?.data?.final_result_from_BRE == "REJECT") {
        window.location.href = '"/dashboard"';
      } else {
        window.location.href = "/loan-approval";
      }
    }
  };

  return (
    <div className="wrapper">
      {!finboxError && <Loader />}

      {finboxError && (
        <div className={styles.finbox__error}>
          <img src="/images/alert.png" alt="" />
          <h3>Oops!</h3>
          <p>
            We are unable to fetch <br /> your bank account
          </p>
          <button className="button" onClick={() => (window.location.href = "/dashboard")}>
            Go Back <i className="shine"></i>
          </button>
        </div>
      )}
    </div>
  );
}
