import React from "react";
import styles from "./Finbox.module.scss";
import { useNavigate } from "react-router-dom";
import MainHeader from "../../components/Header/MainHeader";

export default function FinboxReject() {
  const navigate = useNavigate();

  return (
    <div className="wrapper">
      <div className="vertical-column-layout">
        <div className="vertical-column-top">
          <MainHeader title="" showProgress={false} />
          <div className={styles.finbox__reject}>
            <h2 className="label">Your loan application is rejected.</h2>

            <p>Dear Customer, We regret to inform you that your loan application has been rejected.</p>

            <h3 className={styles.reason__msg}>Possible Reasons Leading to Non-Approval</h3>

            <div className={styles.list}>
              <div className={styles.icon}>
                <img src="/images/credit-history.png" alt="" />
              </div>
              <div className={styles.content}>
                <h4>Credit History</h4>
                <p>Lack of sufficient credit history.</p>
              </div>
            </div>

            <div className={styles.list}>
              <div className={styles.icon}>
                <img src="/images/monthly-income.png" alt="" />
              </div>
              <div className={styles.content}>
                <h4>Monthly Income</h4>
                <p>Your monthly income do not match our criteria.</p>
              </div>
            </div>

            <div className={styles.list}>
              <div className={styles.icon}>
                <img src="/images/documents.png" alt="" />
              </div>
              <div className={styles.content}>
                <h4>Documents</h4>
                <p>Your document information in incorrect.</p>
              </div>
            </div>

            <div className={styles.list}>
              <div className={styles.icon}>
                <img src="/images/age.png" alt="" />
              </div>
              <div className={styles.content}>
                <h4>Age</h4>
                <p>Your age do not falls under 18 to 58.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="vertical-column-bottom">
          <button
            className="button"
            onClick={() => {
              window.location.href = "/dashboard";
            }}
          >
            Continue <i className="shine"></i>
          </button>
        </div>
      </div>
    </div>
  );
}
