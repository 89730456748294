import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { storeLeadID } from "../utils/storage";
import api from "../utils/api";
import { useDispatch, useSelector } from "react-redux";
import { addLeadType, addRepeatCustomer, addUserName, changeLoanApproved } from "../redux/slices/userSlice";
import { utmSourceStorage } from "../lib/script";

function useResponse() {
  // const [isLoanApproved, setLoanApproved] = useState(false);
  const navigate = useNavigate();

  const { isLoanApproved } = useSelector((state) => state.user);

  // console.log("accountId------------", accountId);

  const dispatch = useDispatch();

  const handleStep = async (newAccountId) => {
    // console.log("newAccountId------------", newAccountId);

    const updatedAccountID = newAccountId || "";

    try {
      const endpoint = "step/next-step" + utmSourceStorage();
      const req = {
        leadID: Number(localStorage.getItem("leadID")),
        accountID: updatedAccountID,
      };

      const { data } = await api.post(endpoint, req);

      console.log("Step Data------------", data);

      if (data.statusCode === 200) {
        const existStep = data?.data?.step;

        dispatch(addUserName(data?.data?.customer?.name));

        if (existStep) {
          const { current_route } = data?.data?.step;

          if (data?.data?.leadID) {
            storeLeadID(data.data.leadID);
            dispatch(addLeadType(data?.data?.leadType));
          }

          const status =
            data?.data?.status === "Closed" ||
            data?.data?.status === "Bank Update Rejected" ||
            data?.data?.status === "Rejected" ||
            data?.data?.status === "Not Required" ||
            data?.data?.status === "Not Interested" ||
            data?.data?.status === "Rejected Process" ||
            data?.data?.status === "Not Required Process" ||
            data?.data?.status === "Not Eligible";

          if (status && data?.data?.leadType === "Repeat Case") {
            dispatch(addRepeatCustomer(true));
          }

          // console.log("isLoanApproved-----------", isLoanApproved);

          if (data && data?.data?.status === "Approved Process" && isLoanApproved === false) {
            // window.location.href = "/loan-approval";
            dispatch(changeLoanApproved(true));
            navigate("/loan-approval");
            return;
          }

          setTimeout(() => {
            navigate(current_route);
          }, 100);
        } else {
          navigate("/dashboard");
        }
      } else {
        Swal.fire({
          icon: "error",
          text: data?.message,
        });
      }
    } catch (error) {
      return error?.response?.data;
    }
  };

  return { handleStep };
}

export default useResponse;
