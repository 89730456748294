import React from "react";
import styles from "./EMICalendar.module.scss";

export default function EMICalendar(props) {
  return (
    <div className={styles.emiCalendar}>
      <p>EMICalendra</p>
    </div>
  );
}
