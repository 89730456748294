import React from "react";
import styles from "./EnterName.module.scss";
import { Formik } from "formik";

// components
import DataSafetyMessage from "../../components/Messages/DataSafetyMessage";
import InfoMessage from "../../components/Messages/InfoMessage";
import Loader from "../../components/Loader/Loader";

// methods
import useResponse from "../../hooks/useResponse";
import ServicesAPI from "../../services/services";
import { enterNameValidation } from "../../validations/user.validation";
import { WebEngageTrackingEvent } from "../../lib/webengage";

export default function EnterName() {
  // const navigate = useNavigate();
  const { handleStep } = useResponse();

  const { enterUserNameEmail } = new ServicesAPI();

  return (
    <div className={`wrapper auto-height ${styles.enterName}`}>
      <Formik
        initialValues={{ name: "", email: "" }}
        validationSchema={enterNameValidation}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);

          setTimeout(async () => {
            // try {
            //   await enterUserNameEmail(values.name, values.email);
            //   handleStep();
            // } catch (error) {
            //   console.log("Error:", error);
            // }

            const res = await enterUserNameEmail(values.name, values.email);

            if (res) {
              handleStep();
              WebEngageTrackingEvent("Personal Details Submitted", {
                customer_type: localStorage.getItem("customerType"),
                app_version: localStorage.getItem("appVersion"),
              });
            }

            setSubmitting(false);
          }, 500);
        }}
      >
        {({ errors, values, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit} style={{ height: "100%" }}>
            <div className="vertical-column-layout">
              <div className="vertical-column-top mt-3">
                <h2>
                  🔍 Ensuring Authenticity, <br /> Just For You!
                </h2>

                <p className="label">Enter your name</p>
                <div className="sub-label mb-2">
                  Please enter your name as per <strong>PAN</strong>
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your name as per PAN"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={(e) => {
                      setFieldValue("name", e.target.value.trim());
                    }}
                    autoComplete="off"
                  />

                  {touched.name && errors.name ? <div className="error-msg">{errors.name}</div> : null}
                </div>

                <InfoMessage message="Please enter your correct name as per PAN" />

                <div className="mt-3">
                  <p className="label">Enter your e-mail</p>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your e-mail"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onInput={(e) => {
                        setFieldValue("email", e.target.value);
                      }}
                      onBlur={(e) => {
                        setFieldValue("email", e.target.value.trim());
                      }}
                      autoComplete="off"
                    />
                    {touched.email && errors.email ? <div className="error-msg">{errors.email}</div> : null}
                  </div>
                </div>
              </div>

              <div className="vertical-column-bottom">
                <DataSafetyMessage />
                <button className="button" type="submit">
                  Next <i className="shine"></i>
                </button>
              </div>
            </div>

            {isSubmitting && <Loader />}
          </form>
        )}
      </Formik>
    </div>
  );
}
