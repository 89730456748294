import React from "react";
import styles from "./Contact.module.scss";

// components
import MainHeader from "../../components/Header/MainHeader";
import { Link } from "react-router-dom";

import { HiOutlineMail } from "react-icons/hi";
import { LuPhoneCall } from "react-icons/lu";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

export default function Contact() {
  return (
    <>
      <div className={`wrapper ${styles.contact__us}`}>
        <MainHeader title="Contact Us" showProgress={false} backPageName="/home" />
        <div className="mb-3">
          <p className="label">Contact us for all your queries</p>
        </div>

        <Link to="mailto:info@ramfincorp.com" className={styles.list}>
          <div className={styles.icon}>
            <HiOutlineMail size={22} color="#182bda" />
          </div>
          <div className={styles.content}>
            <div>
              <h5>info@ramfincorp.com</h5>
              <span>Click here to drop an email to us</span>
            </div>
            <div className={styles.arrow}>
              <MdOutlineKeyboardArrowRight color="#000000" size={24} />
            </div>
          </div>
        </Link>

        <Link to="tel:9899985495" className={styles.list}>
          <div className={styles.icon}>
            <LuPhoneCall size={22} color="#182bda" />
          </div>
          <div className={styles.content}>
            <div>
              <h5>+91 98999-85495</h5>
              <span>Click here to call us</span>
            </div>
            <div className={styles.arrow}>
              <MdOutlineKeyboardArrowRight color="#000000" size={24} />
            </div>
          </div>
        </Link>
      </div>
    </>
  );
}
