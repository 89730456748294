import React, { useEffect, useState } from "react";
import styles from "./Emandate.module.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import MainHeader from "../../components/Header/MainHeader";
import Loader from "../../components/Loader/Loader";

import Drawer from "@mui/material/Drawer";
import InfoMessage from "../../components/Messages/InfoMessage";
import AfterOnboardAPI from "../../services/AfterOnboardAPI";
import { useSelector } from "react-redux";

import useResponse from "../../hooks/useResponse";
import { rozarPayMandate } from "../../lib/payments";
import { useTheme } from "@mui/material/styles";
import { WebEngageTrackingEvent } from "../../lib/webengage";

export default function Emandate() {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({ bottom: false });
  const [pennyDropRetry, setPennyDropRetry] = useState(false);

  const theme = useTheme();

  const { accountId } = useSelector((state) => state.user);

  // console.log("mandate accountId============>", accountId);

  const { handleStep } = useResponse();

  const navigate = useNavigate();
  const location = useLocation();

  const { setEmandate, setEmandateV2, pennyDrop } = new AfterOnboardAPI();

  useEffect(() => {
    if (pennyDropRetry) {
      handlePannyDropAPI();
      setPennyDropRetry(false);
    }
  }, [pennyDropRetry]);

  const toggleDrawer = (anchor, open) => (event) => {
    console.log("hi");
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleEmandate = async () => {
    setLoading(true);
    const res = await setEmandate(accountId);

    if (res) {
      if (res.statusCode === 200 && res.message === "Success") {
        if (res.data.status === 1) {
          window.location.href = res.data.registrationLink;
        }
      } else if (res.statusCode === 200 && res.message === "Already Emandate") {
        handlePannyDropAPI();
      } else {
        Swal.fire({
          icon: "error",
          text: res.message,
        });
      }
    }

    setLoading(false);
  };

  const handleEmandateV2 = async () => {
    setLoading(true);
    const res = await setEmandateV2(accountId);

    if (res.statusCode === 200 && res.message === "Success") {
      await rozarPayMandate(res.data);
      WebEngageTrackingEvent("E-mandate Initiated", {
        customer_type: localStorage.getItem("customerType"),
        app_version: localStorage.getItem("appVersion"),
      });
    } else if (res.statusCode === 200 && res.message === "Already Emandate") {
      WebEngageTrackingEvent("E-mandate Completed", {
        customer_type: localStorage.getItem("customerType"),
        app_version: localStorage.getItem("appVersion"),
      });
      handlePannyDropAPI();
    } else {
      Swal.fire({
        icon: "error",
        text: res.message,
      });
    }

    setLoading(false);
  };

  const handlePannyDropAPI = async () => {
    setLoading(true);
    setState({ ...state, bottom: true });

    // Delay before hit the api
    await new Promise((resolve) => setTimeout(resolve, 3000));

    const res = await pennyDrop(accountId);

    if (res) {
      if (res.data.pennyStatus === "completed" || res.data.pennyStatus === "failed") {
        handleStep();
      } else {
        setPennyDropRetry(true);
      }
    } else {
      setLoading(false);
    }

    WebEngageTrackingEvent("Penny Drop Completed", {
      customer_type: localStorage.getItem("customerType"),
      app_version: localStorage.getItem("appVersion"),
    });

    setState({ ...state, bottom: false });
  };

  return (
    <>
      {loading && <Loader />}

      <div className="wrapper">
        <div className="vertical-column-layout">
          <div className="vertical-column-top">
            <MainHeader title="E-mandate" />
            <p className="label">Complete your E-mandate</p>
            <p className="sub-label mb-2">Please complete your e-mandate process for loan disbursal.</p>
            <div>
              <img src="/images/emandate.png" alt="" />
            </div>

            <InfoMessage message="After completing e-mandate process comeback to the application for further process." />
          </div>

          <div className="vertical-column-bottom">
            <button className="button" type="submit" onClick={() => handleEmandateV2()}>
              Continue <i className="shine"></i>
            </button>

            <div className={styles.edit__bank}>
              <Link to="/bank-details" state={{ backScreen: "/emandate" }}>
                Edit Bank Details
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Drawer
          anchor="bottom"
          open={state["bottom"]}
          onClose={toggleDrawer("bottom", true)}
          className={styles.bottomDrawer}
          sx={{
            "& .MuiDrawer-paper": {
              maxWidth: 480,
              width: "100%",
              margin: "0 auto",
              left: "0",
              [theme.breakpoints.up("sm")]: {
                left: -15,
              },
            },
          }}
        >
          <div className={styles.mandateProcessModal}>
            <p className="mb-2">Just wait for a moment</p>
            <div>
              <img src="/images/send-to-bank.svg" alt="" />
            </div>
            <h3>
              Sending ₹ 1 to your <br /> bank for verification
            </h3>
          </div>
        </Drawer>
      </div>
    </>
  );
}
