import React, { useEffect, useState } from "react";
import styles from "./TermsCondition.module.scss";
// import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Checkbox from "@mui/material/Checkbox";
import MainHeader from "../../components/Header/MainHeader";
import AfterOnboardAPI from "../../services/AfterOnboardAPI";
import Loader from "../../components/Loader/Loader";
// import { useSelector } from "react-redux";
import { trackEvent } from "../../lib/google";
import { WebEngageTrackingEvent } from "../../lib/webengage";

const label = { inputProps: { "aria-label": "Checkbox" } };

export default function TermsCondition() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [checkTerm, setCheckTerm] = useState(false);
  // const navigate = useNavigate();

  const { keyFactStatementsAPI, keyFactAceeptanceAPI } = new AfterOnboardAPI();

  useEffect(() => {
    const fetchAPI = async () => {
      const res = await keyFactStatementsAPI();

      if (res) {
        setData(res?.data);
      }

      setLoading(false);
    };

    fetchAPI();
  }, []);

  const handleKeyFactStatus = async () => {
    setLoading(true);

    const res = await keyFactAceeptanceAPI();

    if (res && res.data.status === "1") {
      window.location.href = "/dashboard";
    } else {
      Swal.fire({
        icon: "error",
        text: res.data.message,
      });
    }

    setLoading(false);

    WebEngageTrackingEvent("Accept Terms", {
      customer_type: localStorage.getItem("customerType"),
      app_version: localStorage.getItem("appVersion"),
    });

    trackEvent({
      action: "KFS",
      category: "term-condition",
      label: "Accepted",
      value: 0,
    });
  };

  if (!data) {
    return <Loader />;
  }
  return (
    <>
      <div className="wrapper">
        <div className="vertical-column-layout">
          <div className="vertical-column-top">
            <MainHeader title="Key Fact Statement" backPageName={"/bank-list"} />

            <div className={styles.termContent}>
              <div className={styles.dynamic__content} dangerouslySetInnerHTML={{ __html: data }}></div>
            </div>
          </div>

          <div className="vertical-column-bottom">
            <div className={styles.accept}>
              <div>
                <Checkbox
                  {...label}
                  checked={checkTerm}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                  onChange={(e) => setCheckTerm(e.target.checked)}
                />
              </div>
              <div>I agree to terms-privacy policy and allow access to my credit bureau information.</div>
            </div>
            <button className="button" disabled={!checkTerm} onClick={() => handleKeyFactStatus()}>
              Continue <i className="shine"></i>
            </button>
          </div>
        </div>
      </div>

      {loading && <Loader />}
    </>
  );
}
