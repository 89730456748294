import React from "react";
import AppRoute from "./routes/AppRoute";

export default function App() {
  return (
    <>
      <AppRoute />
    </>
  );
}
