// Get Environment

const getEnvironment = () => {
  const server = process.env.REACT_APP_SERVER;

  switch (server) {
    case "dev":
      return {
        apiUrl: process.env.REACT_APP_API_URL_DEV,
        GA_KEY: process.env.REACT_APP_GOOGLE_ANALYTICS_KEY,
        PAYMENT_URL: process.env.REACT_APP_API_PAYMENT_URL_DEV,
      };

    case "staging":
      return {
        apiUrl: process.env.REACT_APP_API_URL_STAGING,
        GA_KEY: process.env.REACT_APP_GOOGLE_ANALYTICS_KEY,
        PAYMENT_URL: process.env.REACT_APP_API_PAYMENT_URL_STAGING,
      };

    default:
      console.log("Production");
  }
};

export { getEnvironment };
