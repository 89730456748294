import React, { useEffect, useState } from "react";
import styles from "./ConfirmDetails.module.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

// components
import MainHeader from "../../components/Header/MainHeader";
import Loader from "../../components/Loader/Loader";

// services
import useResponse from "../../hooks/useResponse";
import AfterOnboardAPI from "../../services/AfterOnboardAPI";
import { storeLeadID } from "../../utils/storage";
import { WebEngageTrackingEvent } from "../../lib/webengage";

export default function ConfirmDetails() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const newLoanAmount = localStorage.getItem("loanAmount");

  // console.log("newLoanAmount-------------", newLoanAmount);

  const { loanAmount, loanPurpose, paymentMode, monthlyIncome } = useSelector((state) => state.register);
  // const { repeatCustomer } = useSelector((state) => state.user);

  // console.log("loanPurpose-------------", loanPurpose);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { onePageGetDetailsAPI, checkRepeatCaseAPI } = new AfterOnboardAPI();

  // const { handleStep } = useResponse();

  useEffect(() => {
    WebEngageTrackingEvent("One page landed", {
      customer_type: localStorage.getItem("customerType"),
      app_version: localStorage.getItem("appVersion"),
    });

    const fetchOnePageAPI = async () => {
      setLoading(true);
      const res = await onePageGetDetailsAPI();

      // console.log("one page res----------->>>>>>>", res);

      if (res) {
        const data = {
          ...res.data,
          loanPurpose: loanPurpose ? loanPurpose : res.data.loanPurpose,
          modeOfPayment: paymentMode ? paymentMode : res.data.modeOfPayment,
          monthlySalary: monthlyIncome ? monthlyIncome : res.data.monthlySalary,
        };
        setData(data);
      }
      setLoading(false);
    };

    fetchOnePageAPI();
  }, []);

  const handleConfirmDetails = async () => {
    setLoading(true);
    const res = await checkRepeatCaseAPI(data, newLoanAmount || loanAmount);

    console.log("res-------------", res);

    if (res && res.statusCode === 200) {
      if (res?.data?.leadID) {
        storeLeadID(res.data.leadID);
      }

      // if (res.data.status === 0 && res.data.pageName === "rejected") {
      //   navigate("/loan-reject");
      // } else if (res.data.status === 1 && res.data.pageName === "finbox") {
      //   navigate(`/finbox?url=${res?.data?.metaData.finbox.redirect_url}`);
      // } else if (res.data.status === 1 && res.data.pageName === "approval") {
      //   navigate("/loan-approval");
      // } else if (res.data.pageName === "dashboard") {
      //   navigate("/dashboard");
      // }

      if (res.data.pageName === "rejected") {
        window.location.href = "/loan-reject";
        // navigate("/loan-reject");
      } else if (res.data.pageName === "finbox") {
        navigate(`/finbox?url=${res?.data?.metaData.finbox.redirect_url}`);
      } else if (res.data.pageName === "approval") {
        window.location.href = "/loan-approval";
        // navigate("/loan-approval");
      } else if (res.data.pageName === "dashboard") {
        window.location.href = "/dashboard";
        // navigate("/dashboard");
      } else {
        window.location.href = "/dashboard";
        // navigate("/dashboard");
      }

      localStorage.removeItem("loanAmount");

      WebEngageTrackingEvent("One page submitted", {
        customer_type: localStorage.getItem("customerType"),
        app_version: localStorage.getItem("appVersion"),
      });
    }
    setLoading(false);
  };

  if (!data) {
    return <Loader />;
  }
  return (
    <>
      <div className={`wrapper ${styles.confirmDetails__styles}`}>
        <MainHeader title="User Details" showProgress={false} />
        <div className="mb-2">
          <p className="label">Confirm your details</p>
        </div>

        <div className={styles.card}>
          <Link to="/loan-purpose" className={styles.list}>
            <span className={styles.title}>Loan Purpose</span>
            <div>
              <span className={styles.value}>{data.loanPurpose}</span>
              <MdOutlineKeyboardArrowRight size={22} />
            </div>
          </Link>

          <Link to="/marital-status" className={styles.list}>
            <span className={styles.title}>Marital Status</span>
            <div>
              <span className={styles.value}>{data.maritalStatus}</span>
              <MdOutlineKeyboardArrowRight size={22} />
            </div>
          </Link>

          <Link to="/highest-education" className={styles.list}>
            <span className={styles.title}>Educational Qualification</span>
            <div>
              <span className={styles.value}>{data.qualification}</span>
              <MdOutlineKeyboardArrowRight size={22} />
            </div>
          </Link>

          <Link to="/employment-details" className={styles.list}>
            <span className={styles.title}>Employment type</span>
            <div>
              <span className={styles.value}>{data.employementType}</span>
              <MdOutlineKeyboardArrowRight size={22} />
            </div>
          </Link>

          <Link to="/payment-mode" className={styles.list}>
            <span className={styles.title}>Mode of Payment</span>
            <div>
              <span className={styles.value}>{data.modeOfPayment}</span>
              <MdOutlineKeyboardArrowRight size={22} />
            </div>
          </Link>

          <Link to="/company-name" className={styles.list}>
            <span className={styles.title}>Company Name</span>
            <div>
              <span className={styles.value}>{data.companyName}</span>
              <MdOutlineKeyboardArrowRight size={22} />
            </div>
          </Link>

          <Link to="/industry" className={styles.list}>
            <span className={styles.title}>Industry</span>
            <div>
              <span className={styles.value}>{data.industry}</span>
              <MdOutlineKeyboardArrowRight size={22} />
            </div>
          </Link>

          <Link to="/your-designation" className={styles.list}>
            <span className={styles.title}>Designation</span>
            <div>
              <span className={styles.value}>{data.designation}</span>
              <MdOutlineKeyboardArrowRight size={22} />
            </div>
          </Link>

          <Link to="/monthly-income" className={styles.list}>
            <span className={styles.title}>Monthly Salary</span>
            <div>
              <span className={styles.value}>{data.monthlySalary}</span>
              <MdOutlineKeyboardArrowRight size={22} />
            </div>
          </Link>

          <Link to="/salary-date" className={styles.list}>
            <span className={styles.title}>Salary Date</span>
            <div>
              <span className={styles.value}>{data.salaryDate}</span>
              <MdOutlineKeyboardArrowRight size={22} />
            </div>
          </Link>

          <Link to="/your-residence" className={styles.list}>
            <span className={styles.title}>Residential Type</span>
            <div>
              <span className={styles.value}>{data.address.type}</span>
              <MdOutlineKeyboardArrowRight size={22} />
            </div>
          </Link>

          <Link to="/enter-address" className={styles.list}>
            <span className={styles.title}>Residential Address</span>
            <div>
              <span className={`${styles.value} ${styles.address}`}>{data.address.address}</span>
              <MdOutlineKeyboardArrowRight size={22} />
            </div>
          </Link>
        </div>

        <div className="vertical-column-bottom">
          <button className="button" onClick={() => handleConfirmDetails()}>
            Continue <i className="shine"></i>
          </button>
        </div>
      </div>

      {loading && <Loader />}
    </>
  );
}
