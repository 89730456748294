import React from "react";
import styles from "./DashboardHeader.module.scss";
import { IoNotificationsOutline } from "react-icons/io5";
import { useSelector } from "react-redux";

export default function DashboardHeader() {
  const { userName } = useSelector((state) => state.user);

  return (
    <header className={styles.dashboard__header}>
      <div className={styles.brand__info}>
        <div className={styles.logo}>
          <img src="/images/small-logo.png" alt="" />
        </div>
        <div className={styles.brand__name}>Hi, {userName ? userName.split(" ")[0] : "Welcome"} </div>
      </div>

      <div className={styles.notification}>
        <span className={styles.dot}></span>
        <IoNotificationsOutline size={22} color="#585858" />
      </div>
    </header>
  );
}
