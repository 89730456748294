import React, { useEffect, useState } from "react";
import styles from "./EnterAddress.module.scss";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";

// components
import MainHeader from "../../components/Header/MainHeader";
import DataSafetyMessage from "../../components/Messages/DataSafetyMessage";
import Loader from "../../components/Loader/Loader";

// methods
import ServicesAPI from "../../services/services";
import { currentAddressValidation } from "../../validations/user.validation";
import { useDispatch, useSelector } from "react-redux";
import { addUserName } from "../../redux/slices/userSlice";
import { WebEngageTrackingEvent } from "../../lib/webengage";

export default function EnterAddress() {
  const [address, setAddress] = useState(null);
  const [searchState, setSearchState] = useState("");
  const [stateLists, setStateLists] = useState([]);

  // const { handleStep } = useResponse();
  const { repeatCustomer } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { currentAddressUpdate, getStates, getAddressAPI } = new ServicesAPI();

  // console.log("repeatCustomer---------->>>>", repeatCustomer);

  useEffect(() => {
    fetchAddress();
  }, []);

  const fetchAddress = async () => {
    const res = await getAddressAPI();
    if (res && res.statusCode === 200) {
      setAddress(res.data);
      dispatch(addUserName(res.data.name));
    }
  };

  useEffect(() => {
    const handleSearch = setTimeout(() => {
      if (searchState) {
        handleGetStates();
      } else {
        setStateLists([]);
      }
    }, 300);

    return () => clearTimeout(handleSearch);
  }, [searchState]);

  const handleGetStates = async () => {
    const res = await getStates(searchState);
    if (res && res.statusCode === 200) {
      setStateLists(res.data);
    }
  };

  const initialValues = {
    houseNo: address?.address || "",
    city: address?.city || "",
    state: address?.state || "",
    pinCode: address?.pincode || "",
    landmark: address?.landmark || "",
  };

  return (
    <div className="wrapper">
      <Formik
        initialValues={initialValues}
        validationSchema={currentAddressValidation}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);

          setTimeout(async () => {
            const res = await currentAddressUpdate(values, repeatCustomer);

            if (res && res.statusCode === 200) {
              if (repeatCustomer) {
                navigate("/confirm-details");
                return;
              }

              const message = res?.data?.CibilAndBreData?.data?.data?.message;
              const status = res?.data?.CibilAndBreData?.data?.data?.status;

              if (status === 1 && message) {
                switch (message) {
                  case "Approved":
                    navigate("/loan-approval");
                    break;

                  case "Proceed to Bank":
                    navigate(`/finbox?url=${res?.data?.finboxRedirectUrl}`);
                    break;

                  case "BRE Accepted":
                    navigate(`/loan-approval`);
                    break;

                  case "Reject":
                    navigate(`/loan-reject`);
                    break;

                  default:
                    navigate("/loan-approval");
                    break;
                }
              } else if (status === 1) {
                navigate(`/loan-approval`);
              } else if (status != 1 && (message == "Cibil Rejected" || message == "Dtree Bre Rejected")) {
                navigate("/loan-reject");
              }
            }

            WebEngageTrackingEvent("Address Confirmed", {
              customer_type: localStorage.getItem("customerType"),
              app_version: localStorage.getItem("appVersion"),
            });

            setSubmitting(false);
          }, 500);
        }}
      >
        {({ errors, values, touched, isSubmitting, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit} style={{ height: "100%" }}>
            <div className="vertical-column-layout">
              <div className="vertical-column-top">
                <MainHeader
                  title="Residential Details"
                  showProgress={true}
                  percent={100}
                  backPageName={repeatCustomer ? "/confirm-details" : ""}
                />
                <p className="label">Enter your Current Address</p>
                <p className="sub-label mb-3">Is your current address same as aadhar?</p>

                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Flat / House no. / Floor / Building"
                    name="houseNo"
                    value={values.houseNo}
                    onChange={handleChange}
                    onBlur={(e) => {
                      setFieldValue("houseNo", e.target.value.trim());
                    }}
                    autoComplete="off"
                  />

                  {touched.houseNo && errors.houseNo ? <div className="error-msg">{errors.houseNo}</div> : null}
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="City"
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                    onBlur={(e) => {
                      setFieldValue("city", e.target.value.trim());
                    }}
                    autoComplete="off"
                  />

                  {touched.city && errors.city ? <div className="error-msg">{errors.city}</div> : null}
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    className={`form-control ${stateLists.length > 0 ? styles.dropdown : ""}`}
                    placeholder="State"
                    name="state"
                    value={values.state}
                    onChange={(e) => {
                      setSearchState(e.target.value);
                      handleChange(e);
                      setFieldValue("state", e.target.value);
                    }}
                    onBlur={handleBlur}
                    autoComplete="off"
                  />

                  {touched.state && errors.state ? <div className="error-msg">{errors.state}</div> : null}

                  {stateLists.length > 0 && (
                    <div className={styles.autoComplete}>
                      <ul>
                        {stateLists.map((item, index) => (
                          <li
                            key={index}
                            onClick={() => {
                              setFieldValue("state", item);
                              setStateLists([]);
                            }}
                          >
                            {item}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Pincode"
                    name="pinCode"
                    value={values.pinCode}
                    onChange={(e) => {
                      const input = e.target.value;
                      if (/^\d*$/.test(input)) {
                        setFieldValue("pinCode", e.target.value);
                      }
                    }}
                    onBlur={(e) => {
                      setFieldValue("pinCode", e.target.value.trim());
                    }}
                    autoComplete="off"
                    maxLength={6}
                  />

                  {touched.pinCode && errors.pinCode ? <div className="error-msg">{errors.pinCode}</div> : null}
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Nearby Landmark"
                    name="landmark"
                    value={values.landmark}
                    onChange={handleChange}
                    onBlur={(e) => {
                      setFieldValue("landmark", e.target.value.trim());
                    }}
                    autoComplete="off"
                  />

                  {touched.landmark && errors.landmark ? <div className="error-msg">{errors.landmark}</div> : null}
                </div>
              </div>

              <div className="vertical-column-bottom">
                <DataSafetyMessage />
                <button className="button" type="submit">
                  Next <i className="shine"></i>
                </button>
              </div>
            </div>

            {isSubmitting && <Loader />}
          </form>
        )}
      </Formik>
    </div>
  );
}
