import React, { useEffect } from "react";
import Loader from "../../components/Loader/Loader";
import { useDispatch } from "react-redux";

import { persistor } from "../../redux/store";
import { clearUserData } from "../../redux/slices/userSlice";
import { clearRegisterData } from "../../redux/slices/registerSlice";

export default function Logout() {
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.clear();

    dispatch(clearUserData());
    dispatch(clearRegisterData());

    // persistor.pause();
    // persistor.purge();
    // persistor.flush();

    handleRedirect();
  }, [dispatch]);

  const handleRedirect = () => {
    setTimeout(() => {
      window.location.href = "/";
    }, 3 * 1000);
  };

  return (
    <div>
      <Loader />
      <img src="/images/logout.svg" style={{ opacity: 0 }} alt="" />
    </div>
  );
}
